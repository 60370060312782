import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthComponent } from './auth/auth.component';
import { InfoDossierComponent } from './info-dossier/info-dossier.component';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NouveauDossierComponent } from './admin/nouveau-dossier/nouveau-dossier.component';
import { AjoutArticleComponent } from './admin/ajout-article/ajout-article.component';
import { RegroupementComponent } from './admin/regroupement/regroupement.component';
import { ExportComponent } from './admin/export/export.component';
import { TokenInterceptor } from './utils/interceptor/token.interceptor';
import { ApiInterceptor } from './utils/interceptor/api.interceptor';
import { LoadingPageInterceptor } from './utils/interceptor/loading-page.interceptor';


@NgModule({
  declarations: [
    AppComponent,
    //NouveauDossierComponent,
    //AjoutArticleComponent,
    //InfoDossierComponent,
    AuthComponent,
    //RegroupementComponent,
    //ExportComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgbModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true},
    { provide: HTTP_INTERCEPTORS, useClass: LoadingPageInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
