import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { AuthService } from './service/auth.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {

  constructor(private route:Router, private authService: AuthService) { }

  public loading: boolean = false;

  ngOnInit(): void {
  }

  /*login () {
    this.route.navigate(['/admin/accueil']);
  }*/
  login(loginForm: NgForm){
    this.loading = true;
    var datas = loginForm.value;
    if(datas.lastname_agent!="" && datas.password!=""){
      this.authService.login({data : loginForm.value}).subscribe((d:any) => {
        // console.log('SERVICE ', d.agent.on_service);
        if(d.agent.actif === 0){
          Swal.fire('Error', "Connexion non autorisée, votre compte est vérouillé.", 'error');
        }
        else{
          localStorage.setItem('token', d.token);
          localStorage.setItem('agent', JSON.stringify(d.agent));
          localStorage.setItem('role',d.role);
          this.route.navigate(['/admin/accueil']);
        }
      }, (err: any) => {
        if(err.status === 401){
          this.loading = false;
          //Swal.fire('Error', "Nom d'Utulisateur ou Mot de passe incorrect", 'error');
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: "Nom d'Utulisateur ou Mot de passe incorrect",
            showConfirmButton: false,
            timer: 3000
          });
          loginForm.reset();
        }
      })
    }
    else{
      this.loading =false;
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: "Remplir votre champs",
        showConfirmButton: false,
        timer: 3000
      });
    }
  }
}
