import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor() {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const token = localStorage.getItem('token');
        const SUPERADMIN_TOKEN = localStorage.getItem('superadmin_token');
        if(token || SUPERADMIN_TOKEN){
          const cloned = request.clone({
            headers: request.headers.set('Authorization', `Bearer ${ token? token : SUPERADMIN_TOKEN }`)
          });
          return next.handle(cloned);
        }
        return next.handle(request);
    }

}
